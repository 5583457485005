.add_btn {
    background: rgba(254, 116, 79, 0.1);
    color: var(--infi-bg);
    padding: 6px 20px;
}


.wrapper {
    border: 1px solid rgb(197, 197, 197);
    border-radius: 16px;
    padding: 10px 15px;
    margin: 15px 0px;
}

.wrraper_add {
    background: rgba(147, 217, 172, 0.2);
    padding: 15px 10px;
    margin-top: 20px;
    border-radius: 16px;
}

.wrraper_slct {
    background: rgb(175 175 175 / 25%);
    padding: 15px 10px;
    margin-top: 20px;
    border-radius: 16px;
}

.plus_icn {
    border: 1px solid #a7a7a7;
    font-size: 40px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.details>div {
    color: rgba(254, 116, 79, 1);
    font-weight: 600;
    font-size: 18px;
}

.details .details_par {
    color: rgba(254, 116, 79, 1);
    background: rgba(254, 116, 79, 0.1);
    margin-right: 10px;
    padding: 10px 45px;
    border-radius: 10px;
}
/* ----------- */
.leftsidebar{
    border-right:1px solid #ccc;
    height: 100%;
    width: 100%;
}
/* .rightsidebar{
    border-left:1px solid #ccc;
    height: 100%;
    width:100%;
} */
 /* ----------Apexchart Start------------- */
 .recharts-wrapper {
    width: 100%!important;
}
#chart {
    padding-top: 5px;
    overflow-x: hidden;
}
 .apexcharts-toolbar {
    margin-top: 10px;
}
 /* ------------ */

@media only screen and (max-width: 991px) {
    .leftsidebar{
        border:none!important;
    }
  }