button.w-100.btn.btn-default {
    border: 1px solid #d1d1d1;
}

.pp-transacton_icn {
    border: 1px solid gray;
    border-radius: 50%;
    padding: 0px;
    display: inline-block;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal_field .form-control {
    border: 1px solid gray;
    color: black;
    border-radius: 4px;
    font-weight: 500;
    font-size: 19px;
    padding: 6px 7px;
}

.modal_field .form-control::placeholder {
    color: black;
    font-weight: 500;
}

.modal_field label {
    font-weight: 600;
}

.fiels_txt {
    position: absolute;
    top: 4px;
    right: 15px;
    font-size: 21px;
    font-weight: 500;
    color: #fe744f;
    background: white;
}