.nav-link {
    font-size: 16px;
    --bs-nav-link-font-size: 18px;
    --bs-nav-link-font-weight: 600;
    --bs-nav-link-color: black;
}

.navbar-toggler-icon {
    width: 18px;
    height: 18px;
    background-position: bottom center;
    background-size: 130%;
}

.navbar-toggler:focus {
    --bs-navbar-toggler-focus-width: 0px;
}

.header {
    background: white;
    box-shadow: 0px 0px 7px 1px #e1e1e1;
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 10;
    padding: 0px 0px;
}

.header .bg-body-tertiary {
    background-color: white !important;
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 10;
}
.header .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: inherit;
    right: 0;
    margin-top: var(--bs-dropdown-spacer);
}
.btn-group-sm>.btn, .btn-sm {
    line-height: 1.6;
}

body .btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--infi-bg);
    --bs-btn-border-color: var(--infi-bg);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #fdaf9a;
    --bs-btn-hover-border-color: var(--infi-bg);
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--infi-bg);
    --bs-btn-active-border-color: var(--infi-bg);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #dfe9f8;
    --bs-btn-disabled-border-color: #fe744f;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}
body .btn-outline-primary {
    --bs-btn-color: rgba(254, 116, 79, 1);
    --bs-btn-border-color: rgba(254, 116, 79, 1);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: rgba(254, 116, 79, 1);
    --bs-btn-hover-border-color: rgba(254, 116, 79, 1);
    --bs-btn-focus-shadow-rgb: 13,110,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: rgba(254, 116, 79, 1);
    --bs-btn-active-border-color: rgba(254, 116, 79, 1);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: rgba(254, 116, 79, 1);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: rgba(254, 116, 79, 1);
    --bs-gradient: none;
}
body .btn:disabled{
    --bs-btn-disabled-bg: rgba(254, 116, 79, 1);
}

.connect_btn{
    border: 1px solid var(--infi-bg1);
    display: flex;
    align-items: center;
    border-radius: 10px;
}

.connect_btn:hover{
    border: 1px solid var(--infi-bg);
}

.connect_btn span{
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .navbar-collapse {
        padding-bottom: 12px;
    }
  }
