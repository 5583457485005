.listed .table {
    --bs-table-bg: transparent;
}
.listed .table>:not(caption)>*>* {
    border: none;
}
.listed .table tbody tr:hover{
    background-color: #d4e7eb;
}
/* --------------- */
.projectContent a {
    line-height: normal;
}