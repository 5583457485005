.lg_img img {
    height: 100vh;
    width: 100%;
    object-fit: cover;
}

.login .or{
    position: relative;
    margin: 25px 0px;
}

.content_wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login .or::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 45%;
    background: rgb(209, 209, 209);
    top: 13px;
    left: 0%;
}

.login .or::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 45%;
    background: rgb(216, 216, 216);
    top: 13px;
    right: 0%;
}

.login .eye{
    top: 5px;
    right: 10px;
    color: gray;
}
.connect-btn button[data-size="md"] {
    padding: 9px var(--wui-spacing-l) 9px var(--wui-spacing-l);
    width: 100%;
}
.connectwallet .modal-body{
    height: 118px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.connectwallet  button.btn-close {
    z-index: 1;
    padding: 7px!Important;
    border: 1px solid;
    box-shadow: none;
    opacity: 1;
    border-radius: 100px;
    border-color: rgba(254, 116, 79, 1);
    border-width: 2px;
    font-size: 9px;
}