.slide_project{
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid rgba(173, 173, 173, 1);
    padding: 8px 10px;
    border-radius: 30px;
}

.slide_project span{
    font-weight: 700;
    color: black;
    font-size: 22px;
}

.slide_project small{
    background: rgba(115, 197, 143, 0.4);
    color: rgba(0, 100, 68, 1);
    padding: 3px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}