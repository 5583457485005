
.accordion-style .accordion-header button {
    font-weight: 700;
    color: #000;
}
.accordion {
    --bs-border-radius:0px;
}
.accordion-button::after{
    --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M2 5L8 11L14 5'/%3E%3C/svg%3E);
}
.accordion-style .accordion-item{
    border-top: 1px solid #dee2e6;
    border-top-left-radius: var(--bs-accordion-inner-border-radius);
    border-top-right-radius: var(--bs-accordion-inner-border-radius);
    border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
    border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-button:not(.collapsed) {
    --bs-accordion-active-bg: #5ec4dd;
}
.accordion-button:focus {
    --bs-accordion-btn-focus-box-shadow:none;
}
