.des_footer {
    background: rgba(94, 196, 221, 1);
    color: white;
    padding: 10px 30px;
}

.des_footer a {
    text-decoration: none;
}

.des_footer .links a {
    font-weight: 500;
    margin-right: 20px;
}

.icons_wrapper {
    display: flex;
    align-items: center;
}

.icons_wrapper a {
    color: white;
    margin-right: 10px;
    font-size: 20px;
}