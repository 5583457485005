@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300..900&display=swap');
/* @font-face {
  font-family: Darker Grotesque;
  src: url(../src/style/font/DarkerGrotesque/DarkerGrotesque-VariableFont_wght.ttf);
} */


:root {
  --infi-bg: rgba(254, 116, 79, 1);
  --infi-bg1: #65ac7e;
  --infi-bg2: #5ec3dd;
  --bs-heading-color: black;
  --bs-body-color: black;
}


body {
  --bs-body-font-family: "Darker Grotesque", sans-serif;
  --bs-body-color: black;
  /* --bs-body-font-weight: 500; */
  --bs-body-font-weight: 500;
  font-optical-sizing: auto;
  font-style: normal;
  --bs-link-color-rgb: rgba(254, 116, 79, 1);
}

body .btn {
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 700;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin-top: 0;
  font-family: "Darker Grotesque", sans-serif;
  margin-bottom: .5rem;
  font-weight: 700;
  line-height: 1.2;
  color: black;
}
.text-primary {
  --bs-primary-rgb:101, 172, 126;
}
select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7' viewBox='0 0 12 7' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.224502 0.224502C-0.0748342 0.523839 -0.0748339 1.00916 0.224502 1.3085L5.43682 6.52081C5.44354 6.52807 5.45043 6.53523 5.45749 6.54229C5.60726 6.69206 5.80359 6.76689 5.99989 6.76679C6.19619 6.76689 6.39252 6.69206 6.54229 6.54229C6.54934 6.53523 6.55624 6.52807 6.56296 6.52081L11.7753 1.3085C12.0746 1.00916 12.0746 0.523839 11.7753 0.224502C11.4759 -0.0748341 10.9906 -0.0748341 10.6913 0.224502L5.99989 4.9159L1.30849 0.224502C1.00916 -0.0748341 0.523838 -0.0748341 0.224502 0.224502Z' fill='black'/%3E%3C/svg%3E");
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: 97%;
}
