.highlight_content p {
    font-weight: 500;
    font-size: 18px;
}

.Highlights .about a {
    color: var(--bs-link-color-rgb) !important;
    text-decoration: none !important;
    font-weight: 500;
}

.Highlights .about .icn {
    color: #94a7c6;
    font-size: 18px;
}

.scnd_tab {
    border: 1px solid rgb(211, 211, 211);
    border-radius: 20px;
}

.scnd_tab .head {
    background: #5ec4dd;
    padding: 15px 14px;
    border-radius: 20px 20px 0px 0px;
}

.scnd_tab .content {
    border-bottom: 1px solid rgb(211, 211, 211);
    padding: 17px 14px;
}

.listing_app_form,
.listing_app_form div {
    font-weight: 500;
}