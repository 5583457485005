table td, table th {
  vertical-align: middle;
  white-space: nowrap;
}

.item_info_counts .item_info_type{
    background: rgba(26, 167, 236, .1);
}
.item_info_counts {
    display: flex;
    align-items: stretch;
    margin-bottom: 20px;
}
.item_info_counts .item_info_type {
    font-weight: 600;
    padding: 2px 10px;
    font-size: 14px;
    text-align: center;
    min-width: 80px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}
.detail .item_info_counts ul li {
    display: inline-block;
    margin-right: 20px;
}

.detail .price_attr {
  margin-right: 11px;
  padding-right: 11px;
  border-right: 1px solid #ccc;
  display: inline-block;
  height: 50px;
}
.detail .price_attr:last-child {
    border: none;
}
.detail .buyselect select {
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
}
.detail .buyselect {
    padding-top: 15px;
    padding-right: 15px;
    /* float: right; */
    position: absolute;
    top: 0;
    right: 0;
}
.maxbtn {
    color: #fe744f;
    position: relative;
    z-index: 3;
}
/* /--------- */
.tab-style1 .nav-tabs .nav-item.show .nav-link, .tab-style1 .nav-tabs .nav-link.active {
    color: #000;
}
.tab-style1 .nav-tabs .nav-link{
    border-radius: 0px;
}

.infivideo video {
        position: absolute;
        right: 0;
        /* top: 0; */
        height: 100%;
        z-index: -1;
        /* width: 100%; */
        object-fit: contain;
}

.content_detail {
    /* background: url("../../../public/images/banner/InfiBannerimage2.jpg"); */
    /* background-color: #000; */

    /* background: linear-gradient(90deg, black 19%, #5773ff); */
}
.infivideo video {
  position: absolute;
  right: 0;
  /* top: 0; */
  height: 100%;
  z-index: -1;
  width: 100%;
  object-fit: contain;
}
input::-webkit-input-placeholder { font-size: 13px; }
input::-moz-placeholder { font-size: 13px; }
 /* ------------- */
 .loader_parent {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  z-index: 1111111;
  backdrop-filter: blur(5px);
}
.loader {
  position: fixed;
  border: 12px solid #e8ebf0;
  right: 44%;
  left: 44%;
  top: 38%;
  border-radius: 50%;
  border-top: 12px solid #72c58f;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* ------------ */
.animation-circle-main {
    display: block;
    position: absolute;
    right:12%;
    width: 100%;
    opacity:1;
    height: 100%;
	max-width: 767px;
	max-height: 767px;
    top: 50%;
	transform: translateY(-50%);
}
.animation-circle-main svg{
	opacity:0.1; 
}
.stipe-value-slider{
	position:absolute;
	bottom:0px;
	width:100%;
	left:0px;
	right:0px;
	background:#fbbd18;     
}
.c-logo {
    position: absolute;
    top: 50%;
    max-width: 360px;
    transform: translate(-50%, -50%);
    left: 50%;
}
#circle5 {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transform-origin: 288.5px 279.5px;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-animation: rotate-left 25s linear 0s infinite;
}
#circle4 {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transform-origin: 288.5px 279.5px;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-animation: rotate-left 80s linear 0s infinite;
}
#circle6 {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transform-origin: 288.5px 279.5px;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-animation: rotate-right 20s linear 0s infinite;
}
#circle7 {
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transform-origin: 288.5px 279.5px;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-animation: rotate-right 20s linear 0s infinite;
}
#number1{
	-webkit-animation: top-bottom 10s linear 0s infinite;
	animation: top-bottom 10s linear 0s infinite;
}
#number2{
	-webkit-animation: top-bottom-2 15s linear 0s infinite;
	animation: top-bottom-2 15s linear 0s infinite;
}
@keyframes rotate-right {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate-left {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
  }
}
@-webkit-keyframes top-bottom {
  from {
    -webkit-transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(100%);
  }
}
@-webkit-keyframes top-bottom-2 {
  from {
    -webkit-transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(100%);
  }
}
/* ----Swap form start------ */
.swapbtn {
  font-size: 18px;
  min-width: 100px;
  background: #dddddd24 !important;
  border-top-left-radius: 0px!important;
  border-bottom-left-radius: 0px!important;
  overflow: hidden;
}
.swapIcon button {
  display: flex;
  justify-content: end;
}
.swapIcon {
  display: flex;
  justify-content: center;
}
.switchBtn .form-check-input:checked {
  background-color: #fe744f;
  border-color: #fe744f;
}
.switchBtn .form-check-input:focus {
  border-color: #dee2e6;
  outline: 0;
  box-shadow: none;
}
.switchBtn {
  line-height: 1;
}
.switchBtn .form-check-input {
  margin-top: -1px;
  cursor: pointer;
}
.btn-swap {
  background: #fff!important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.Swapform .form-control {
  border-top-right-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
 
}
/* ---------- */

@media only screen and (max-width: 767px) {
    .item_info_counts .item_info_type {
        margin-bottom: 10px;
    }
    .detail .price_attr {
        margin-bottom: 10px;
        width: 100%;
        padding-left: 25px;
        margin-right: 0;
    }
   
  }
  @media only screen and (max-width: 575px) {
  
    .detail .buyselect {
        padding-top: 15px;
        display: flex;
        padding-right: 0px;
        position: relative;
        top: 0;
        right: 0px;
        justify-content: end;
    }
    .maxbtn {
        padding-right: 12px;
        color: #fe744f;
    }
    .Sawpform {
        padding: 20px 20px!important;
        padding-top: 0px !important;
    }

}