.projectList {
    background: #dff3f8;
}

.nav-tabs .nav-link {
    border: none;
    font-weight: 700;
    font-size: 20px;
    color: gray;
}

.nav-tabs .nav-link.active {
    border-bottom: 2px solid rgb(37, 37, 37);
    background-color: transparent;
}

.nav-link:hover {
    color: var(--infi-bg1);
}

.form-control:focus {
    box-shadow: none;
}

.mb-3.input-group {
    border: 1px solid #ebebeb;
    border-radius: 6px;
}

.projectList .form-control {
    border: none;
}

.form-control::placeholder {
    font-weight: 500;
    font-size: 16px;
}

.input-group-text {
    border: none;
    background: white;
}

.card {
    border-radius: 20px;
    border: 1px solid var(--infi-bg2);
}

.btn-dark {
    --bs-btn-bg: #000000;
}

.img_wrapper {
    overflow: hidden;
    border-radius: 10px;
}

.card:hover .crd_img {
    transform: scale(1.2);
}

.crd_img {
    transition: 0.5s;
    height: 150px;
    width: 100%;
    object-fit: contain;
}
/* ---------------- */
.projectContent {
    min-height: 126px;
}
.card.activeBox {
    background: linear-gradient(0deg, #8bd4e7 20%, #fff, #fff);
}