.btn_light {
    background: rgba(255, 80, 0, 0.1);
    color: var(--infi-bg);
    border: none;
}

.axverse .box .head_c {
    color: rgba(94, 195, 221, 1);
}

.axverse .box input {
    border: 1px solid rgba(173, 173, 173, 1)
}

.box {
    border: 1px solid rgb(199, 199, 199);
    padding: 25px;
    border-radius: 18px;
}

.batch {
    background: rgba(199, 255, 236, 1);
    color: #10b981;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 9px;
    border-radius: 6px;
    vertical-align: sub;
}