.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px;
    z-index: 9999;
  }
  
  .cookie-banner button {
    margin: 0 10px;
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .cookie-banner button:hover {
    background-color: #45a049;
  }
  