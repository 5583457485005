section.sidebar {
    box-shadow: 0px 0px 7px 1px #e1e1e1;
    height: 100vh;
    position: absolute;
    padding-top: 100px;
    top: 0px;
    left: 0px;
    background: white;
}

.css-1wvake5.ps-collapsed {
    width: 0px !important;
    min-width: 0px !important;
}

.css-dip3t8 {
    background-color: white !important;
}

.css-1t8x7v1>.ps-menu-button:hover {
    background-color: rgba(254, 116, 79, 0.08) !important;
}

.css-1t8x7v1>.ps-menu-button {
    padding: 0px !important;
    margin: 5px 30px;
    border-radius: 10px;
}

.main_outlet_wrapper {
    height: 85vh;
    overflow-y: scroll;
}

.ps-active a {
    background-color: rgba(254, 116, 79, 0.08) !important;
}

span.ps-menu-icon.css-wx7wi4 {
    font-size: 21px;
}