.banner {
    position: relative;
}

.banner_img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.banner_content h5,
.banner_content h3 {
    text-transform: uppercase;
    font-weight: 700;
}

.banner_content {
    position: absolute;
    top: 20%;
    color: white;
    left: 15%;
    z-index: 5;
}

.clr_1 {
    color: var(--infi-bg2);
}

.clr_2 {
    color: var(--infi-bg1);
}

.clr_3 {
    color: var(--infi-bg);
}


.btn-outline-light {
    text-transform: uppercase;
    font-weight: 500;
    --bs-btn-color: #f8f9fa;
    --bs-btn-border-color: #f8f9fa;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #f8f9fa;
    --bs-btn-hover-border-color: #f8f9fa;
    --bs-btn-focus-shadow-rgb: 248, 249, 250;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #f8f9fa;
    --bs-btn-active-border-color: #f8f9fa;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #f8f9fa;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #f8f9fa;
    --bs-gradient: none;
}

.bannerPrice_section {
    background-color: #dff3f8;
}

.swiper.swiper-initialized.swiper-vertical.swiper-backface-hidden {
    height: 60vh;
}

.bannerPrice_section p small {
    font-weight: 600;
}
.hover\:bg-\[\#4A287E\]:hover {
    --tw-bg-opacity: 1;
    background-color: #dff3f8;
}

@media only screen and (max-width: 1024px) {
    .banner_img {
        object-position: 80%;
    }
}
@media only screen and (max-width: 500px) {
    .swiper-slide {
      background: #000;
    }
    .swiper-slide img {
      opacity: 0.4;
    }
    .banner_img {
      object-position: 82%;
  }
  }