.footer .icons span{
    color: var(--infi-bg2);
    font-size: 25px;
    transition: 0.4s;
}

.footer .icons span:hover{
    color: var(--infi-bg);
}

.footer_section b{
    font-size: 20px;
    font-weight: 700;
}

.footer_section div{
    font-size: 18px;
    font-weight: 600;
}
.strategicpartners {
    border-right: 1px solid #dee2e6;
}

@media only screen and (max-width: 575px) {
    .strategicpartners {
        border-right: none;
    }
  }