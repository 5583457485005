.process_form .from_box {
    border: 1px solid rgba(117, 117, 117, 0.2);
    background: rgba(245, 248, 255, 1);
    border-radius: 20px;
    padding: 25px 75px;
}

.process_form .from_box label {
    font-weight: 700;
}

.process_form input::placeholder, .process_form textarea::placeholder {
    font-weight: 500;
    font-size: 16px;
}

.custom_dragdrop {
    background: rgba(233, 236, 239, 1);
    border: 1px dashed rgba(173, 181, 189, 1);
    padding: 30px 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom_dragdrop_form {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    opacity: 0.0;
}

.process_form .btn:first-child:active{
    box-shadow: none;
    border-color: transparent;
}

.process_form .btn-add{
    --bs-btn-color: rgba(7, 164, 207, 1);
}

@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
    .process_form .from_box {
        padding: 25px 0px;
    }
}