.progress-bar {
  --bs-progress-bar-bg: rgba(16, 185, 129, 1);
}

body .progress {
  --bs-progress-height: 30px;
  width: 100%;
}

.form-select:focus {
  border-color: #fff1ed !important;
  box-shadow: 0 0 0 .25rem rgb(255 241 237) !important;
}

.section_space {
  margin-top: 8% !important;
}

@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
  .banner_content {
    left: 0% !important;
        padding: 0px 10px;
        width: 100%;
  }

  .btn {
    font-size: 15px !important;
  }

  .banner_content h5,
  .banner_content p,
  .banner_content h4 {
    font-size: 14px !important;
    margin-bottom: 5px;
  }

  body .btn {
    --bs-btn-font-size: 11px;
    --bs-btn-font-weight: 700;
    --bs-btn-padding-x: 5px;
    --bs-btn-padding-y: 5px;
  }

  .logo img {
    height: 20px;
    width: auto;
  }

  .logo .ms-0 img {
    height: 10px;
    width: auto;
  }




  /*-------------- project list -------------- */

}

@media screen and (max-device-width: 533px) {
  .logo img {
    height: 40px;
    width: auto;
  }
  .header .w3m-button {
    display: block;
}
}